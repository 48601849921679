import React from "react"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FlyingEarthFlagPage = ({ location }) => (
  <Layout location={location} title="Flying the Earth Flag">
    <SEO title="Flying the Earth Flag" />
    <section id="flying-earth-flag-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            The Earth Flag is a simple and powerful symbol and we ask everyone
            to consider flying one.
          </p>
          <p>
            Celebrate our common ground, the beauty of our world and the
            experiences Planet Earth provides for us.
          </p>
          <p>
            Those interested in speaking to us about a Flying The Earth Flag
          </p>
          <div className="d-flex justify-content-start my-4">
            <Link
              className="btn btn-donate hvr-shadow"
              to="/flying-the-earth-flag-in-2020"
            >
              Flying the Earth Flag in 2020
            </Link>
          </div>

          <div className="container">
            <div className="content text-white">
              <div className="intro text-center px-md-5 mb-2">
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  animateOnce={true}
                >
                  <h2>Earth Flags, Banners & Globes</h2>
                </ScrollAnimation>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <a
                        href="https://shop.earthseeds.org/products/16-astronauts-globe-inflatable-earth"
                        alt="Astro Globe"
                      >
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/astronuats-globe-2020-set1-web1.JPG"
                            alt="Astronauts Globe"
                          />
                        </figure>
                        <h4>The Astronauts Globe</h4>
                      </a>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={400}
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <a
                        href="https://shop.earthseeds.org/products/the-original-whole-earth-photo-earth-flag"
                        alt="Earth Flag"
                      >
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/earth-banner2x3-2020-set1-web1.JPG"
                            alt="Earth Flag"
                          />
                        </figure>
                        <h4>The Earth Flag</h4>
                      </a>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={600}
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <a
                        href="https://shop.earthseeds.org/products/the-original-whole-earth-photo-earth-banner-2x3ft"
                        alt="Earth Banner"
                      >
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/earth-banner2x3-2020-set1-web1.JPG"
                            alt="Earth Banner"
                          />
                        </figure>
                        <h4>The Earth Banner</h4>
                      </a>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FlyingEarthFlagPage
